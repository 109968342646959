@media (min-width: 768px) {
    .my-footer {
        width: 100%;
        height: 100%;
        max-width: 1000px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        padding: 0 10px;
    }

    .my-footer>div:nth-of-type(2) {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: end;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        font-family: 'MyCustomFont', sans-serif;
    }

    .my-footer>div:nth-of-type(2)>div:nth-of-type(1) {
        margin-bottom: 6px;
    }

    .my-footer>.but {
        font-size: 16px;
        color: rgba(216, 216, 216, 1) !important;
        font-family: 'MyCustomFont', sans-serif;
    }
}

@media (max-width: 768px) {
    .my-footer {
        width: 100%;
        height: 100%;
        max-width: 767px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px;
    }

    .my-footer>div:nth-of-type(2) {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        font-family: 'MyCustomFont', sans-serif;
    }

    .my-footer>div:nth-of-type(2)>div:nth-of-type(1) {
        margin-bottom: 6px;
    }

    .my-footer>.but {
        font-size: 16px;
        color: rgba(216, 216, 216, 1) !important;
        padding: 0;
        font-family: 'MyCustomFont', sans-serif;
    }
}