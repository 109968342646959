@font-face {
    font-family: 'MyCustomFont';
    src: url('./assets/fonts/Montserrat-Regular-dcfe8df2.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'MyCustomFont', sans-serif;
}

@media (min-width: 768px) {
    .layout-style {
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    .layout-style .header-style {
        width: 100%;
        height: 10rem;
        max-height: 100px;
        color: #fff;
        padding: 0;
        margin: 0;
        position: fixed;
        top: 0;
        overflow: hidden;
        z-index: 999;
    }

    .layout-style .content-style {
        min-height: calc(100vh - clamp(50px, 29.6rem, 296px));
        color: #fff;
    }

    .layout-style .footer-style {
        width: 100%;
        height: 19.6rem;
        max-height: 196px;
        color: #fff;
        background-color: rgba(0, 0, 0, 1);
        padding: 0;
        margin: 0;
    }
}

@media (max-width: 768px) {
    .layout-style {
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    .layout-style .header-style {
        width: 100%;
        height: 6.6rem;
        max-height: 66px;
        color: #fff;
        padding: 0;
        margin: 0;
    }

    .layout-style .content-style {
        min-height: calc(100vh - 114px);
        color: #fff;
    }

    .layout-style .footer-style {
        width: 100%;
        height: 48px;
        color: #fff;
        background-color: rgba(0, 0, 0, 1);
        padding: 0;
        margin: 0;
    }
}