@media (min-width: 768px) {
    .my-header {
        width: 100%;
        height: 100%;
        max-width: 1000px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
    }

    .my-header .logo {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .my-header .logo img {
        width: 3.4rem;
        height: 3.4rem;
        max-width: 34px;
        max-height: 34px;
        display: block;
        margin-left: 10px;
    }

    .my-header .logo div {
        font-weight: 600;
        font-size: clamp(12px, 2rem, 20px);
        margin-left: 6px;
        color: rgba(255, 255, 255, 1);
        font-family: 'MyCustomFont', sans-serif;
    }

    .my-header .anchor {
        margin-right: 10px;
    }

    .my-header .anchor .ant-anchor {
        font-size: 16px;
        font-weight: 700;
        font-family: 'MyCustomFont', sans-serif;
    }

    .my-header .anchor .ant-anchor-wrapper::before {
        border-bottom: none;
    }

    .my-header .anchor .ant-anchor-ink {
        background-color: var(--anchor-ink-color, #fff);
    }

    .my-header .anchor .ant-anchor-link-active>.ant-anchor-link-title {
        color: var(--active-color, #fff);
    }
}

@media (max-width: 768px) {
    .my-header {
        width: 100%;
        height: 100%;
        max-width: 767px;
        background-color: rgba(0, 0, 0, 1);
        position: relative;
    }

    .my-header .logo {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .my-header .logo img {
        width: 3rem;
        max-width: 30px;
        height: 3rem;
        max-height: 30px;
        display: block;
    }

    .my-header .anchor {
        position: absolute;
        top: 0;
        left: 0;
    }

    .my-header .anchor>div {
        width: 100%;
        height: 100%;
    }

    .my-header .anchor .fixed-but {
        width: 40px;
        height: 40px;
        position: fixed;
        top: 10px;
        left: 10px;
        z-index: 999;
        color: rgba(255, 255, 255, 1);
        background-color: rgba(0, 0, 0, 1);
    }

    .my-drawer .ant-drawer-content-wrapper {
        height: auto !important;
    }

    .my-drawer .ant-drawer-content-wrapper .ant-drawer-content {
        background-color: rgba(0, 0, 0, 1);
    }

    .my-drawer .ant-drawer-content-wrapper .ant-drawer-header {
        padding: 0;
        border: none;
    }

    .my-drawer .ant-drawer-content-wrapper .ant-drawer-header .close-but {
        width: 40px;
        height: 40px;
        margin: 10px;
        color: rgba(255, 255, 255, 1);
    }

    .my-drawer .ant-drawer-content-wrapper .ant-drawer-body {
        padding: 0 24px 24px 24px;
    }

    .my-drawer .ant-drawer-content-wrapper .ant-drawer-body .ant-anchor::before {
        content: none;
    }

    .my-drawer .ant-drawer-content-wrapper .ant-drawer-body .ant-anchor .ant-anchor-link {
        padding: 15px 30px;
        font-size: 16px;
        line-height: 16px;
        font-weight: 300;
        font-family: 'MyCustomFont', sans-serif;
        border-bottom: 1px solid rgba(102, 102, 102, 1);
    }

    .my-drawer .ant-drawer-content-wrapper .ant-drawer-body .ant-anchor .ant-anchor-link>a {
        color: rgba(255, 255, 255, 1);
    }

    .my-drawer .ant-drawer-content-wrapper .ant-drawer-body .ant-anchor .ant-anchor-ink {
        width: 5px;
        height: 48px !important;
        background-color: rgba(102, 102, 102, 1);
    }

    .my-drawer .ant-drawer-content-wrapper .ant-drawer-body .ant-anchor .ant-anchor-link-active {
        font-weight: 700;
        background-color: rgba(102, 102, 102, .3);
    }

    .my-drawer .ant-drawer-content-wrapper .ant-drawer-body .ant-anchor .ant-anchor-link-active>a {
        color: rgba(255, 255, 255, 1);
    }
}