@media (min-width: 768px) {
    #home {
        margin-top: clamp(10px, 10rem, 100px);
        background-color: rgba(13, 13, 13, 1);
    }

    #home .bg-box {
        width: 100%;
        max-width: 1000px;
        aspect-ratio: 1440 / 782;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
        background-position: 50% 50%;
        margin: 0 auto;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }

    #home .bg-box div:nth-child(1) {
        font-size: 60px;
        font-family: 'MyCustomFont', sans-serif;
        color: rgba(255, 255, 255, 1);
    }

    #home .bg-box div:nth-child(2) {
        margin-top: 12px;
        font-size: 28px;
        color: rgba(255, 255, 255, 1);
        font-family: 'MyCustomFont', sans-serif;
    }
}

@media (max-width: 768px) {
    #home {}

    #home .bg-box {
        width: 100vw;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
        background-position: 50% 50%;
        margin-top: -60px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }

    #home .bg-box div:nth-child(1) {
        width: 100%;
        font-size: 40px;
        font-family: 'MyCustomFont', sans-serif;
        color: rgba(255, 255, 255, 1);
        text-align: center;
    }

    #home .bg-box div:nth-child(2) {
        width: 100%;
        text-align: center;
        margin-top: 36px;
        font-size: 28px;
        color: rgba(255, 255, 255, 1);
        font-family: 'MyCustomFont', sans-serif;
    }
}