@media (min-width: 768px) {
    .tensor-ai {
        width: 100%;
        margin-top: clamp(10px, 10rem, 100px);
        color: rgba(0, 0, 0, 1);
        font-family: 'MyCustomFont', sans-serif;
    }

    .tensor-ai .header {
        width: 100%;
        background-color: rgba(13, 13, 13, 1);
    }

    .tensor-ai .header .header-bg {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        height: 428px;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
        background-position: 50% 50%;
        display: flex;
        align-items: end;
    }

    .tensor-ai .header .lyo {
        width: 100%;
        min-height: 318px;
        display: flex;
        justify-content: space-between;
    }

    .tensor-ai .header .lyo .img {
        width: 42%;
        max-width: 604px;
    }

    .tensor-ai .header .lyo .img img {
        width: 100%;
        height: auto;
        display: block;
    }

    .tensor-ai .header .lyo .text {
        flex: 1;
        padding: 0 90px;
    }

    .tensor-ai .header .lyo .text .title {
        font-size: 46px;
        line-height: 46px;
        color: rgba(255, 255, 255, 1);
        font-weight: 700;
        font-family: 'MyCustomFont', sans-serif;
        margin-bottom: 32px;
    }

    .tensor-ai .header .lyo .text .content {
        font-size: 20px;
        line-height: 40px;
        color: rgba(216, 216, 216, 1);
        font-family: 'MyCustomFont', sans-serif;
    }

    .tensor-ai .header .lyo .text .content .conclusion {
        font-weight: 700;
    }

    .tensor-ai .info {
        width: 100%;
        height: 256px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding-top: 32px;
    }

    .tensor-ai .info .title {
        font-size: 32px;
        font-weight: 700;
        color: rgba(51, 51, 51, 1);
        font-family: 'MyCustomFont', sans-serif;
        margin-bottom: 26px;
    }

    .tensor-ai .info .content {
        font-size: 18px;
        color: rgba(51, 51, 51, 1);
        font-family: 'MyCustomFont', sans-serif;
    }

    .tensor-ai .body {
        width: 100%;
        max-width: 1000px;
        margin: 70px auto;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: start;
        gap: clamp(10px, 3vw, 30px);
    }

    .tensor-ai .body .card {
        width: 31%;
    }

    .tensor-ai .body .card:hover .img>div {
        opacity: 1;
    }

    .tensor-ai .body .card .img {
        width: 100%;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
    }

    .tensor-ai .body .card .img>div {
        position: absolute;
        top: 36px;
        left: 30px;
        font-weight: 500;
        font-size: 24px;
        color: rgba(255, 255, 255, 1);
        font-family: 'MyCustomFont', sans-serif;
        opacity: 0;
        transition: opacity 0.5s ease;
    }

    .tensor-ai .body .card .img>img {
        width: 100%;
        height: auto;
        display: block;
    }

    .tensor-ai .body .card .content {
        margin-top: 30px;
    }

    .tensor-ai .body .card .content>div:nth-of-type(1) {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: 24px;
        color: rgba(51, 51, 51, 1);
        margin-bottom: 16px;
    }

    .tensor-ai .body .card .content>div:nth-of-type(2) {
        font-family: 'MyCustomFont', sans-serif;
        color: rgba(102, 102, 102, 1);
        font-weight: 300;
        font-size: 16px;
        line-height: 26px;
    }
}

@media (max-width: 768px) {
    .tensor-ai {
        width: 100%;
        color: rgba(0, 0, 0, 1);
        font-family: 'MyCustomFont', sans-serif;
    }

    .tensor-ai .header {
        width: 100%;
        background-color: rgba(13, 13, 13, 1);
    }

    .tensor-ai .header .header-bg {
        width: 100%;
        max-width: 720px;
        min-height: 616px;
        margin: 0 auto;
        padding: 0 24px;
    }

    .tensor-ai .header .lyo {
        width: 100%;
        min-height: 456px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }

    .tensor-ai .header .lyo .img {
        width: 100%;
        max-width: 748px;
    }

    .tensor-ai .header .lyo .img img {
        width: 100%;
        height: auto;
        display: block;
    }

    .tensor-ai .header .lyo .text {
        padding: 60px 0;
    }

    .tensor-ai .header .lyo .text .title {
        font-size: 46px;
        line-height: 46px;
        color: rgba(255, 255, 255, 1);
        font-weight: 700;
        font-family: 'MyCustomFont', sans-serif;
        margin-bottom: 24px;
        text-align: center;
    }

    .tensor-ai .header .lyo .text .content {
        font-size: 20px;
        line-height: 40px;
        color: rgba(216, 216, 216, 1);
        font-family: 'MyCustomFont', sans-serif;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .tensor-ai .header .lyo .text .content .conclusion {
        font-weight: 700;
    }

    .tensor-ai .info {
        width: 100%;
        padding: 24px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding-top: 32px;
    }

    .tensor-ai .info .title {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        color: rgba(51, 51, 51, 1);
        font-family: 'MyCustomFont', sans-serif;
        margin-bottom: 26px;
    }

    .tensor-ai .info .content {
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
        font-family: 'MyCustomFont', sans-serif;
    }

    .tensor-ai .body {
        width: 100%;
        max-width: 720px;
        margin: 70px auto;
        padding: 0 24px;
    }

    .tensor-ai .body .card {
        margin-top: 70px;
        width: 100%;
    }

    .tensor-ai .body .card .img {
        width: 100%;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
    }

    .tensor-ai .body .card .img>div {
        position: absolute;
        top: 36px;
        left: 30px;
        font-weight: 500;
        font-size: 24px;
        color: rgba(255, 255, 255, 1);
        font-family: 'MyCustomFont', sans-serif;
        opacity: 0;
    }

    .tensor-ai .body .card .img>img {
        width: 100%;
        height: auto;
        display: block;
    }

    .tensor-ai .body .card .content {
        margin-top: 30px;
    }

    .tensor-ai .body .card .content>div:nth-of-type(1) {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: 24px;
        color: rgba(51, 51, 51, 1);
        margin-bottom: 16px;
    }

    .tensor-ai .body .card .content>div:nth-of-type(2) {
        font-family: 'MyCustomFont', sans-serif;
        color: rgba(102, 102, 102, 1);
        font-weight: 300;
        font-size: 16px;
        line-height: 26px;
    }
}