@media (min-width: 768px) {
    #products {
        background-color: rgba(13, 13, 13, 1);
    }

    #products>.title {
        text-align: center;
        font-size: 24px;
        font-family: 'MyCustomFont', sans-serif;
        color: rgba(255, 255, 255, 1);
        padding: 75px 0;
    }

    #products .synopsis {
        padding-top: 70px;
        width: 100%;
        font-family: 'MyCustomFont', sans-serif;
        color: rgba(34, 34, 34, 1);
    }

    #products .synopsis .banner {
        width: 100%;
        padding: 0 10px;
    }

    #products .synopsis .banner:nth-of-type(2) {
        background-color: rgba(36, 36, 36, 1);
    }

    #products .synopsis .banner>div {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
    }

    #products .synopsis .banner .lyo {
        width: 100%;
        min-height: 442px;
        display: flex;
        justify-content: space-between;
        padding: 70px 0 70px;
        background-repeat: no-repeat;
        background-size: 54%;
        background-color: transparent;
        background-position: 10% 50%;
    }

    #products .synopsis .banner:nth-of-type(2) .lyo {
        background-size: 48%;
        background-position: 90% 50%;
    }

    #products .synopsis .lyo .img {
        width: 41%;
        max-width: 595px;
    }

    #products .synopsis .lyo .img img {
        width: 100%;
        height: auto;
        border-radius: 8px;
        display: block;
    }

    #products .synopsis .lyo .action {
        width: 51%;
        max-width: 732px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: start;
    }

    #products .synopsis .lyo .action .but {
        width: 200px;
        height: 64px;
        border-radius: 18px;
        border: 2px solid #FFFFFF;
        font-weight: 500;
        font-size: 20px;
        color: #FFFFFF;
        background-color: transparent;
    }

    #products .synopsis .lyo .action .but:active,
    #products .synopsis .lyo .action .but:hover {
        background-color: rgba(0, 0, 0, 1);
    }

    #products .synopsis .lyo .action .text .title {
        font-size: 46px;
        line-height: 46px;
        color: rgba(255, 255, 255, 1);
        font-weight: 700;
        font-family: 'MyCustomFont', sans-serif;
        margin-bottom: 24px;
    }

    #products .synopsis .lyo .action .text .content {
        text-align: left;
        font-size: 20px;
        line-height: 40px;
        color: rgba(216, 216, 216, 1);
        font-family: 'MyCustomFont', sans-serif;
    }

    #products .synopsis .lyo .action .text .content .conclusion {
        font-weight: 700;
    }

    #products .information {
        width: 100%;
        max-width: 1000px;
        margin: 70px auto;
        padding: 0 10px;
        font-family: 'MyCustomFont', sans-serif;
        color: rgba(255, 255, 255, 1);
    }

    #products .information .title {
        text-align: center;
        font-size: 36px;
        font-family: 'MyCustomFont', sans-serif;
        margin-bottom: 14px;
    }

    #products .information .introduce {
        text-align: center;
        font-size: 16px;
        font-family: 'MyCustomFont', sans-serif;
        margin-bottom: 40px;
    }

    #products .information .content {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 86px;
    }

    #products .information .content-two {
        margin-top: 52px;
        justify-content: end;
    }

    #products .information .content .item {
        width: 30%;
        max-width: 308px;
        height: 64px;
        border-radius: 32px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        font-family: 'MyCustomFont', sans-serif;
        border: 2px solid rgba(255, 255, 255, 1);
    }

    #products .tags {
        width: 100%;
        height: 256px;
        background: rgba(36, 36, 36, 1);
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }

    #products .tags .title {
        font-size: 36px;
        color: rgba(255, 255, 255, 1);
        font-family: 'MyCustomFont', sans-serif;
        margin-bottom: 26px;
    }

    #products .tags .content {
        font-size: 16px;
        color: rgba(255, 255, 255, 1);
        font-family: 'MyCustomFont', sans-serif;
    }
}

@media (max-width: 768px) {
    #products {
        background-color: rgba(13, 13, 13, 1);
    }

    #products>.title {
        text-align: center;
        font-size: 24px;
        font-family: 'MyCustomFont', sans-serif;
        color: rgba(255, 255, 255, 1);
        padding-top: 60px;
    }

    #products .synopsis {
        padding-top: 60px;
        width: 100%;
    }

    #products .synopsis .banner {
        width: 100%;
        margin: 0 auto;
        padding: 60px 24px;
        font-family: 'MyCustomFont', sans-serif;
        color: rgba(255, 255, 255, 1);
    }

    #products .synopsis .banner:nth-of-type(2) {
        background-color: rgba(36, 36, 36, 1);
    }

    #products .synopsis .lyo {
        width: 100%;
        display: flex;
        flex-flow: column;
        min-height: 226px;
        background-repeat: no-repeat;
        background-size: 100%;
        background-color: transparent;
        background-position: 50% 50%;
    }

    #products .synopsis .banner:nth-of-type(2) .lyo {
        width: 100%;
        display: flex;
        flex-flow: column-reverse;
        min-height: 226px;
    }

    #products .synopsis .lyo .img {
        width: 100%;
        padding-bottom: 30px;
    }

    #products .synopsis .lyo .img img {
        width: 100%;
        height: auto;
        border-radius: 8px;
        display: block;
    }

    #products .synopsis .lyo .action {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: start;
    }

    #products .synopsis .lyo .action .but {
        margin-top: 30px;
        width: 199px;
        height: 63px;
        border-radius: 18px;
        border: 2px solid #FFFFFF;
        font-weight: 500;
        font-size: 20px;
        color: #FFFFFF;
        background-color: transparent;
        align-self: flex-end;
    }

    #products .synopsis .lyo .action .but:active,
    #products .synopsis .lyo .action .but:hover {
        background-color: rgba(0, 0, 0, 1);
    }

    #products .synopsis .lyo .action .text .title {
        font-size: 24px;
        line-height: 24px;
        color: rgba(255, 255, 255, 1);
        font-weight: 700;
        font-family: 'MyCustomFont', sans-serif;
        margin-bottom: 20px;
    }

    #products .synopsis .lyo .action .text .content {
        text-align: left;
        font-size: 20px;
        color: rgba(216, 216, 216, 1);
        font-family: 'MyCustomFont', sans-serif;
    }

    #products .synopsis .lyo .action .text .content .conclusion {
        font-weight: 700;
    }

    #products .information {
        width: 100%;
        max-width: 768px;
        padding: 0 24px;
        font-family: 'MyCustomFont', sans-serif;
        color: rgba(255, 255, 255, 1);
        margin-top: 60px;
        margin-bottom: 60px;
    }

    #products .information .title {
        text-align: center;
        font-size: 36px;
        font-family: 'MyCustomFont', sans-serif;
        margin-bottom: 20px;
    }

    #products .information .introduce {
        text-align: center;
        font-size: 16px;
        font-family: 'MyCustomFont', sans-serif;
        margin-bottom: 40px;
        color: rgba(216, 216, 216, 1);
    }

    #products .information .content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 40px;
    }

    #products .information .content-two {
        margin-top: 40px;
    }

    #products .information .content .item {
        width: 100%;
        height: 64px;
        border-radius: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        font-family: 'MyCustomFont', sans-serif;
        border: 2px solid rgba(255, 255, 255, 1);
    }

    #products .tags {
        width: 100%;
        height: 322px;
        background: rgba(36, 36, 36, 1);
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }

    #products .tags .title {
        width: 315px;
        font-size: 36px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-family: 'MyCustomFont', sans-serif;
        margin-bottom: 26px;
    }

    #products .tags .content {
        width: 270px;
        text-align: center;
        font-size: 16px;
        color: rgba(255, 255, 255, 1);
        font-family: 'MyCustomFont', sans-serif;
    }
}